/**
 * This file exposes frequently used check functions.
 *
 * These functions are auto-imported in components and pages and can be used
 * directly in templates, <script setup> or anywhere in the Options API without
 * having to manually import them.
 */

/**
 * Check if a value is null or undefined.
 *
 * @param value - The value to check.
 * @returns `true` if the value is not null or undefined, `false` otherwise.
 *
 * @example hasValue(10) // true
 */
export const hasValue = (value?: unknown) => value !== null && value !== undefined
