export default async function useRentalData(slug: string) {
  const { rvApi } = useApi()

  // Fetch the RV
  const { data: rv, error } = await rvApi.getRvListDetails(slug, {
    name: slug,
    includeNegativeReviews: true,
  })

  // Handle API errors.
  if (error.value?.statusCode) {
    handleRentalError(error.value.statusCode, slug)
  }

  /**
   * Handles errors that come from the Listings API.
   *
   * TODO: extract into global function that all data fetchers can use.
   */
  function handleRentalError(statusCode: number, slug: string) {
    showError({
      statusCode: statusCode,
      statusMessage: `There was an issue fetching this RV: (${slug})`,
    })
  }

  return {
    rv,
  }
}
