<template>
  <div
    ref="card"
    v-element-visibility="scrolledIntoView"
    scrollable
    :title="campground.name"
    :class="['campground-card', { pulse: isStillLoading }]"
    :data-testid="`campground-card-${index}`"
  >
    <div
      class="campground-images"
      @click.prevent="campgroundClickHandler"
    >
      <Transition name="fade">
        <div
          v-if="isStillLoading"
          key="loader"
          class="skel"
        />
        <LazyAppCarouselImages
          v-else
          key="content"
          :images="photos"
          :img-width="450"
          :img-height="300"
          :resolution="imageResolution"
          :preload="Boolean(photos.length && index === 0)"
          :eager-loading="index === 0 && isVisible"
          @click="onCarouselClick"
          @slide-changed="updateImageIndex"
        />
      </Transition>
    </div>

    <div class="campground-info">
      <div class="campground-header">
        <Transition name="fade">
          <div
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <div
            v-else
            class="campground-title"
          >
            {{ campground.name }}
          </div>
        </Transition>
      </div>

      <div class="campground-meta">
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel skel2 w-4/5"
          />
          <p
            v-else
            key="content"
          >
            {{ campground.placeName }}
            <template v-if="showDistanceAway">
              ({{ t('away', { distance: distanceAway }) }})
            </template>
          </p>
        </Transition>
      </div>

      <div class="campground-link">
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <p
            v-else
            key="content"
          >
            <ZButton
              v-if="$device.isIos"
              link
              variant="primary"
              :href="campgroundLink"
            >
              {{ t('moreInfo') }}
            </ZButton>
            <ZButton
              v-else
              link
              variant="primary"
              :href="campgroundLink"
              @click.prevent="moreInfoClickHandler"
            >
              {{ t('moreInfo') }}
            </ZButton>
          </p>
        </Transition>
      </div>

      <div class="campground-delivery">
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <ZButton
            v-else-if="showDelivery"
            key="content"
            @click="addDeliveryClickHandler"
          >
            <fa :icon="['fas', 'plus']" />
            {{ t('addDelivery') }}
          </ZButton>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { vElementVisibility } from '@vueuse/components'
import { DistanceUnitEnumKey, ImageResolution } from '~/lib/enums'
import { trackCTAClicked, trackExternalLinkClicked } from '~/lib/tracking'

export default {
  name: 'CampgroundCard',

  directives: { elementVisibility: vElementVisibility },

  props: {
    /**
     * The actual index of the item in the list. This is used to emit back to the
     * list in order to tell the list when to load the next set of items.
     */
    index: {
      type: Number,
      default: 0,
    },

    campground: {
      type: Object,
      default: () => ({}),
    },

    distanceUnit: {
      type: String,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    cta: {
      type: String,
      default: null,
    },

    showDelivery: {
      type: Boolean,
      default: false,
    },

    /**
     * Determines wether or not a card should load when it scrolls into view
     * (default), or imediately (useful for SEO).
     */
    loadWhenVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['add-delivery', 'scrolled-into-view'],

  setup() {
    const { t } = useI18n({
      useScope: 'local',
    })

    const { routeBaseName } = useBaseName()

    return { t, routeBaseName }
  },

  data() {
    return {
      imageIndex: 0,

      /**
       * Tracks wether or not the card is scrolled into view.
       */
      isVisible: false,
    }
  },

  computed: {
    /**
     * The card can be in 2 loading "states":
     * 1. The data is actually still loading from the API.
     * 2. The card hasn't scrolled into view yet (if enabled).
     */
    isStillLoading() {
      return this.isLoading || !this.isVisible
    },

    distanceAway() {
      return `${Math.round(this.distanceUnit === DistanceUnitEnumKey.mi ? kmToMi(this.campground.distance) : this.campground.distance)} ${this.distanceUnit}`
    },

    photos() {
      if (!this.campground?.photos) {
        return []
      }

      return this.campground.photos.map((path) => ({
        Path: `${this.$config.public.apps.campgrounds.assetsURL}/Campground/${this.campground.id}/${path}?width=300&height=200`,
        Alt: this.campground.name,
      }))
    },

    imageResolution() {
      return ImageResolution.MEDIUM
    },

    showDistanceAway() {
      return this.cta !== 'map'
    },

    campgroundLink() {
      const campgroundI18nLink = `${this.$config.public.apps.campgrounds.baseUrl}/${this.$i18n.locale}`

      // Early exit if we don't have a campground id with the based campground link.
      if (!this.campground?.id) return campgroundI18nLink

      return `${campgroundI18nLink}/campground/${this.campground.id}`
    },
  },

  created() {
    /**
     * Determine wether or not we want to only show data when the card is
     * scrolled into view.
     */
    this.isVisible = !this.loadWhenVisible
  },

  methods: {
    /**
     * Triggers when the card has scrolled into (or out of) view.
     */
    scrolledIntoView(visible) {
      /**
       * If we don't want to lazy load content, or when the card has scrolled
       * out of view, exit early.
       *
       * Otherwise fire the 'scrolled-into-view' event and track it locally.
       */
      if (!this.loadWhenVisible || !visible) return

      this.$emit('scrolled-into-view', this.index)

      this.isVisible = visible
    },

    onCarouselClick(e) {
      if (e.target.closest('.glide__bullet') || e.target.closest('.glide__arrow')) {
        e.stopPropagation()
        e.preventDefault()
      }
    },

    updateImageIndex(index) {
      this.imageIndex = index
    },

    moreInfoClickHandler() {
      if (this.isStillLoading) return

      trackExternalLinkClicked({
        pageSource: this.routeBaseName,
        cta: 'moreInfo',
        externalLink: this.campgroundLink,
      })

      window.open(this.campgroundLink)
    },

    addDeliveryClickHandler() {
      if (this.isStillLoading) return

      trackCTAClicked({
        pageSource: this.routeBaseName,
        cta: 'addDelivery',
      })

      this.$emit('add-delivery', { name: this.campground.name, address: this.campground.fullAddress })
    },

    campgroundClickHandler() {
      if (this.isStillLoading) return

      trackExternalLinkClicked({
        pageSource: this.routeBaseName,
        cta: 'carouselImage',
        externalLink: this.campgroundLink,
      })

      window.open(this.campgroundLink)
    },
  },
}
</script>

<style lang="scss" scoped>
.pulse {
  cursor: wait;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
}

.skel {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
  border-radius: 0.25rem;
  background-color: getColor('gray-100');

  + .skel2 {
    height: 20px;
    top: 20px;
  }
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: calc(100% * (1 / 3));
}

.w-2\/3 {
  width: calc(100% * (2 / 3));
}

.w-4\/5 {
  width: calc(100% * (4 / 5));
}

.campground-images {
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  padding-bottom: calc(2 / 3 * 100%);
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 0.75rem;
}

.campground-card {
  position: relative;
  overflow: hidden;
  color: getColor('primary-500');

  &:hover {
    text-decoration: none;
  }

  .campground-image {
    border-radius: 1rem;
    overflow: hidden;

    :deep(img) {
      border-radius: 1rem;
    }
  }

  .campground-header {
    position: relative;
    height: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .campground-title {
    @include strong-1;

    position: relative;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.5rem;
    margin: 0;
  }

  .campground-meta {
    @include caption;

    color: getColor('primary-350');
    position: relative;

    p {
      margin: 0;
    }
  }

  .campground-info {
    position: relative;
    margin-top: 0.75rem;
    color: getColor('primary-500');

    &:hover {
      text-decoration: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "away": "{distance} away",
    "moreInfo": "More info",
    "addDelivery": "Add Delivery"
  },
  "fr": {
    "away": "à {distance}",
    "moreInfo": "Plus d'info",
    "addDelivery": "Ajouter la livraison"
  }
}
</i18n>
