<template>
  <div class="koa-campgrounds">
    <h3 class="mb-4 koa-header">
      <span>{{ t('closestKOACampgrounds') }}</span>
      <ZButton
        link
        variant="primary"
        :href="campgroundLink"
      >
        {{ t('viewAllCampgrounds') }}
      </ZButton>
    </h3>
    <GridList
      :results-length="campgrounds?.length ?? 0"
      :page-size="quantity"
      :rows="1"
    >
      <template #default="{ visibleResults }">
        <CampgroundsCard
          v-for="(card, index) in visibleResults"
          :key="card.pregen_id"
          :index="index"
          :distance-unit="rvMeasurementUnit"
          :campground="campgrounds?.[index]"
          :is-loading="pending"
          :show-delivery="rvHasPerUnitDelivery && (campgrounds?.[index]?.distance ?? 0) <= rvDeliveryMaxDistanceInKm"
          @add-delivery="emit('add-delivery')"
        />
      </template>
    </GridList>
  </div>
</template>

<script setup lang="ts">
import type { CampgroundSearch, PlaceSearch } from '~/types/campground'

const props = withDefaults(defineProps<{
  quantity?: number
}>(), {
  quantity: 3,
})

const runtimeConfig = useRuntimeConfig()
const { locale, t } = useI18n()

const {
  rvLocation,
  rvMeasurementUnit,
  rvHasPerUnitDelivery,
  rvDeliveryMaxDistanceInKm,
} = useRvDetails()

const { data: campgrounds, pending } = await useLazyFetch(`${runtimeConfig.public.apps.campgrounds.apiURL}/Campground/Search`, {
  params: {
    PageSize: props.quantity,
    Latitude: rvLocation.value?.Latitude,
    Longitude: rvLocation.value?.Longitude,
    LanguageCode: locale.value,
    Brand: 'KOA',
  },
  transform: (data: CampgroundSearch) => data?.results ?? [],
})

const { data: defaultPlace } = await useLazyFetch(`${runtimeConfig.public.apps.places.apiURL}/Search`, {
  params: {
    PageSize: 1,
    Latitude: rvLocation.value?.Latitude,
    Longitude: rvLocation.value?.Longitude,
    Country: rvLocation.value?.Country,
    PlaceType: 'City',
    DistanceKm: 1000,
    LanguageCode: locale.value,
  },
  transform: (data: PlaceSearch) => data?.results?.[0],
})

const emit = defineEmits(['add-delivery'])

const campgroundLink = computed(() => {
  const campgroundI18nLink = `${runtimeConfig.public.apps.campgrounds.baseURL}/${locale.value}`

  return `${campgroundI18nLink}/${defaultPlace.value?.route ?? ''}`
})
</script>

<style lang="scss" scoped>
.koa-campgrounds {
  :deep(.grid-list) {
    @include media-min-size(medium) {
      --columns: 3;
    }

    > * {
      margin-bottom: 0;
    }
  }

  .koa-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    a {
      padding: 0;
      white-space: nowrap;
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "closestKOACampgrounds": "Nearby KOA campgrounds",
    "viewAllCampgrounds": "View campgrounds"
  },
  "fr": {
    "closestKOACampgrounds": "Campings KOA à proximité",
    "viewAllCampgrounds": "Voir campings"
  }
}
</i18n>
